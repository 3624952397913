<template>
  <el-container>
    <el-menu :default-active="$route.path" :collapse="true" router>
      <el-menu-item index="/home">
        <i class="el-icon-s-home"></i>
        <template #title>Home</template>
      </el-menu-item>
      <el-menu-item index="/home/upload">
        <i class="el-icon-upload2"></i>
        <template #title>Upload</template>
      </el-menu-item>
      <li style="flex: 1"></li>
      <el-menu-item index="/home/about">
        <i class="el-icon-warning-outline"></i>
        <template #title>About</template>
      </el-menu-item>
    </el-menu>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
import Vue from "vue";
import { Container, Main, Menu, MenuItem, Tooltip } from "element-ui";
const components = [Container, Main, Menu, MenuItem, Tooltip];
components.forEach((component) => {
  Vue.use(component);
});
export default {
  name: "Home",
  components: {},
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html,
body,
#app,
.el-container {
  padding: 0px;
  margin: 0px;
  height: 100%;
}
body {
  overflow: hidden;
}
.el-menu:not(.el-menu--collapse) {
  width: 180px;
}
.el-menu {
  display: flex;
  flex-direction: column;
}
.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
}
</style>